.dashboard-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .dashboard-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .file-label {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
    cursor: pointer;
  }
  
  .file-input,
  .input-field {
    padding: 10px;
    width: 80%;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .divider {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #888;
  }
  
  .questions-section {
    margin-bottom: 30px;
  }
  
  .questions-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .question-form {
    margin-bottom: 20px;
  }
  
  .question-field {
    margin: 10px 0;
  }
  
  .options-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .option-input {
    margin-top: 10px;
  }
  
  .add-question-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-question-button:hover {
    background-color: #218838;
  }
  
  .submit-section {
    text-align: center;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .submit-button.loading {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    color: green;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .error-message {
    color: red;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .video-preview {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .current-time {
    font-size: 1.2rem;
    margin-top: 10px;
    color: #333;
  }
  
  .add-question-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .add-question-button:hover {
    background-color: #218838;
  }
  