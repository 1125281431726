html {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: "scrollbar";
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.question-paper {
  font-family: 'Noto Sans Devanagari', sans-serif;
}

.form_style {
  position: relative;
  outline: 0px;
  margin: 12px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 24px;
  background-color: rgb(255, 255, 255);
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgb(231, 235, 240);
  border-image: initial;
  width: 380px;
}

.editTeacher-form_style {
  position: relative;
  outline: 0px;
  /* margin: 12px; */
  /* display: flex; */
  -webkit-box-pack: center;
  justify-content: center;
  padding: 24px;
  background-color: rgb(255, 255, 255);
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgb(231, 235, 240);
  border-image: initial;
  /* width: 380px; */
}

.facilities-form_style {
  background-color: #fff;
  border-color: #e7ebf0;
  border-image: none;
  border-image: initial;
  border-style: solid;
  border-width: 1px 0;
  justify-content: center;
  /* margin: 12px; */
  outline: 0;
  padding: 24px;
  position: relative;
}

.css-w04xiv {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

.css-1qsxih2 {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

.border-style {
  border-radius: 10px;
  border-left-width: 1px;
  border-right-width: 1px;
}

.ForgotPassword {
  margin-right: -58% !important;
  margin-top: 1% !important;
}

.ForgotPassword a {
  text-decoration: none !important;
}

.switchMember a {
  text-decoration: none !important;
}

.switchMember div {
  margin-top: 12% !important;
}

.cardFooter {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.logo {
  margin-left: 2px;
  display: "flex";
  font-family: "monospace";
  font-weight: 700 !important;
  letter-spacing: ".3rem";
}

.logo a {
  color: "inherit" !important;
  text-decoration: "none" !important;
}

.css-19kzrtu {
  padding: 10px !important;
}

.ant-calendar-range {
  width: 320px !important
}

@media (max-width: 480px) {
  .ant-calendar-range {
    width: 320px !important;
  }

  .ant-calendar-range-part {
    width: 100% !important;
  }
}

.footerLink {
  cursor: pointer;
  color: inherit !important;
  text-decoration: none !important;
}

.footerLink:hover {
  color: #1976D2 !important;
}

.footerLinkMenu {
  display: inline-block;
  cursor: pointer;
  color: inherit !important;
  text-decoration: none !important;
  padding: 5px;
  justify-content: space-between !important;
}

@media only screen and (max-width: 600px) {
  .footer {
    display: inline-flex !important;
    justify-content: space-between !important;
  }

  .footerLinkMenu {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.footerLinkMenu:hover {
  color: #002884 !important;
}

.Date .react-datepicker__view-calendar-icon input {
  border: 1px solid gray !important;
  border-radius: 5px !important;
  color: gray !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.6rem !important;
  margin-left: -5px !important;
  box-sizing: content-box;
  cursor: pointer !important;
}

@media (max-width: 556px) {
  .Date {
    display: block !important;
  }
}


.css-1ex1afd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px !important;
}

.courses_desc {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* color: #444; */
  /* display: -webkit-box; */
  font-family: Varela Round, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.courses_title {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* color: #444; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Badge */
.css-zza0ns-MuiBadge-badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 20px;
  line-height: 1;
  padding: 0 6px;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #1976d2;
  color: #fff;
  top: 0;
  right: 11% !important;
  left: 10;
  -webkit-transform: scale(1) translate(50%, -50%);
  -moz-transform: scale(1) translate(50%, -50%);
  -ms-transform: scale(1) translate(50%, -50%);
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
@media (max-width: 450px) {
  .css-zza0ns-MuiBadge-badge {
    right: 12% !important;
  }
}

@media (min-width: 900px) {
  .css-xdmu94-MuiContainer-root {
    max-width: 100% !important;
  }
}

.css-10h7jov {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto !important;
}

/* Student Testimonial */
.our-awesome-team {
  padding: 20px;
  overflow: hidden;
}

.team-card-wrapper {
  padding: 10px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.team-card {
  box-shadow: 0 0 40px 0 rgba(78, 78, 78, 0.1) !important;
  height: 100%;
  width: 350px;
  margin: 10px;
  flex-direction: column;
}

.image-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.team-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.description {
  text-align: center;
}

.vendor_about_description {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500 !important;
  /* text-align: center; */
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.video_form_style {
  background-color: #fff;
  border-color: #e7ebf0;
  border-image: none;
  border-image: initial;
  border-style: solid;
  border-width: 1px 0;
  /* display: flex; */
  justify-content: center;
  /* margin: 12px; */
  outline: 0;
  padding: 24px;
  position: relative;
  max-width: 100%;
}

.css-1c0cfyw {
  max-width: 100%;
  display: block;
}

.css-1dg6kk3-MuiTypography-root {

  margin-left: 12px !important;
  margin-right: 12px !important;
}
.css-1m67wze-MuiTypography-root {
  margin: 0;
  font-family: "Poppins, sans-serif" !important;
  line-height: 1.8 !important;
  letter-spacing: 2.5px !important;
  color: "#555" !important;
  font-weight: 900 !important;
  padding-top: 8px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.question-img {
  width: 100px; /* You can adjust the size based on your layout */
  max-width: 400px; /* Ensures that the images do not grow too large */
  height: 80px;
  margin-top: 8px;


}

.option-img {
  max-width: 100px; /* Limit the size of the option images */
  height: 80px;
  margin-top: 8px;
  margin-right: 10px; /* Spacing between multiple images */
}
