:root {
    --primary: #0F3460;
    --secondary: #ff5722;
    --success: #6fbf71;
    --info: #00bcd4;
    --warning: #e47943;
    --danger: #f44336;
    --light: #f1f1f1;
    --dark: #212121;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-white {
    background-color: #fff !important;
}

.text-white {
    color: white !important;
}

.bg-success {
    background-color: var(--success);
    border-radius: 12px;
    color: white;
}

.bg-error {
    border-radius: 12px;
    background-color: rgba(208, 30, 30, 0.834) !important;
    color: white;
}