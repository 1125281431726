.video-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; 
  }
  
  .red-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    color: white;
    padding: 15px 30px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s;
  }
  
  .red-button:hover {
    background-color: darkred;
  }
  
  /* Question popup */
  .question-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1001;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    min-width: 300px;
    text-align: center;
  }
  
  /* Option buttons */
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .option-button p {
    margin: 0; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 100%; 
    text-align: center; 
    line-height: 25px; 
  }
  
  .option-button {
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
  }
  
  .option-button.selected {
    background-color: lightblue;
  }
  
  .option-button.selected p span{
    background-color: lightblue !important;
    color: black !important;
  }

  /* Submit button */
  .submit-answer-button {
    padding: 12px 24px;
    margin-top:15px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

  }
  
  .submit-answer-button:hover {
    background-color: darkgreen;
  }
  
  /* Result Button */
  .view-results-button {
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: blue;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1002;
    transition: background-color 0.3s;
  }
  
  .view-results-button:hover {
    background-color: darkblue;
  }
  
  /* Result popup */
  .result-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1003;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    min-width: 300px;
    text-align: center;
  }
  
  .result-item {
    margin-bottom: 10px;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }
  
  .close-result-button {
    padding: 12px 24px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .close-result-button:hover {
    background-color: darkblue;
  }
  
  
  .fullscreen {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999 !important;
  }
  
  
  .result-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 20px;
    border-radius: 8px;
  }
  
  .result-popup h2 {
    color: #fff;
    margin-bottom: 20px;
    text-align: center; 
  }
  
  .result-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  
  .result-item-row {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .result-item-row strong {
    color: #333;
  }
  
  .result-item-row {
    display: flex;
    justify-content: space-between;
  }
  
  .close-result-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    align-self: center; 
  }
  
  .close-result-button:hover {
    background-color: #0056b3;
  }
  