.card {
    box-shadow: 0 0 1px gray;
  }
  
  .card-lg {
    box-shadow: 0 0 2px rgb(128, 128, 128);
  }
  
  .divider {
    border-bottom: 1px solid rgb(182, 182, 182);
    margin: 10px 0;
  }
  
  .option {
    box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1.5px;
    padding: 10px;
    border-radius: 2px;
  }
  
  .selected-option {
    box-shadow: rgba(0, 0, 0, 0.638) 0px 0px 1px;
    padding: 10px;
    border-radius: 2px;
    border: 2px solid var(--primary);
  }
  
  .result {
    box-shadow: rgba(75, 75, 75, 0.638) 0px 0px 2px;
    padding: 30px;
    color: white !important;
    border-radius: 5px;
  }
  
  .lottie-animation {
    height: 300px;
  }
  
  .timer {
    background-color: #2196f3;
    color: white !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    /* height: auto; */
    width: 70px;
    /* width: auto; */
    border-radius: 10%;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.userProfile {
  background-color: #fdfefff0 !important;
  color: black;
  font-weight: 600;
  border: 2px solid rgb(234, 231, 231);
  border-radius: 12px;
  
}