  .question-number {
    background-color: black;
    color: white;
    font-size: 17px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .question-number.selected {
    background-color: yellowgreen;
  }

  .question-number.unselected {
    background-color: pink;
  }


  /* --------------------- */
  .exam-info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    /* margin-top: 25px; */
  }

  .info-item {
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .info-badge {
    font-size: 17px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .info-badge.negative {
    background-color: red;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }

  .info-badge.answered {
    background-color: green;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }

  .info-badge.exam-type {
    background-color: blue;
    border-radius: 8px;
    /* No border-radius: 50% */
    padding: 0 15px;
    /* Adjust padding for better text spacing */
    height: auto;
    /* Adjust height for better text spacing */
    width: auto;
  }

  .info-badge.single {
    background-color: blue;
  }

  .info-badge.multiple {
    background-color: orange;
  }

  .time-display {
    font-size: 1.1rem;
    /* Adjust font size for time display */
    padding: 5px 10px;
    /* Add padding for better spacing */
    background-color: rgb(184, 0, 216);
    /* Add background color for time display */
    border-radius: 5px;
    /* Add border-radius for time display */
    color: #FFF;
  }

  /* Media Queries */
  @media (max-width: 600px) {
    .exam-info-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .info-item {
      font-size: 0.9rem;
    }

    .info-badge {
      padding: 0 8px;
      /* Adjust padding for mobile */
    }

    .info-badge.negative,
    .info-badge.answered {
      width: 35px;
      height: 35px;
      font-size: 0.9rem;
    }

    .time-display {
      font-size: 1rem;
      /* Adjust font size for time display in mobile */
    }
  }

 